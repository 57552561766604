import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { httpsCallable } from "firebase/functions";
const app = initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID
});
function formatFireError(code) {
  switch (code) {
    case "auth/user-not-found":
      return "Email or password incorrect";
    case "auth/wrong-password":
      return "password incorrect";
    default:
      return "Error occurred, please try again later.";
  }
}
const functions = getFunctions(app);

const callService = async (serviceName, params = {}) => {
  const callableFirebaseFunction = httpsCallable(functions, serviceName);
  return callableFirebaseFunction(params);
};

export { callService, formatFireError };
