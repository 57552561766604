import React from "react"
import PropTypes from "prop-types"

import AOS from 'aos'

import Cookies from './cookies'

import 'aos/dist/aos.css';

class Layout extends React.Component {
  constructor() {
    super()
    this.state = {
      resize: false,
    }
  }
  componentDidMount() {
    AOS.init({
      duration: 1000,
      once: true
    });
    this.hideLoading()
  }

  hideLoading() {
    const loadingScreen = document.getElementById('loadingScreen')
    loadingScreen && loadingScreen.remove()
  }
  render() {
    const { children } = this.props;

    return (
      <>
        <main className="main">
          {children}
        </main>
        <Cookies />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
