import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {checkIfCookieExists, setCookie} from '../../utils';

import './cookies.scss'

const cookie = {name: 'cookies_agreement', value: true};

class Cookies extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    this.checkForCookie();
  }

  checkForCookie = () => {
    const cookieExists = !checkIfCookieExists(cookie.name);
    if (this.state.isOpen !== cookieExists) {
      this.setState({isOpen: cookieExists});
    }
  };

  handleAgreement = () => {
    setCookie(cookie);
    this.handleClose();
  };

  handleClose = () => {
    if (this.state.isOpen) {
      this.setState({isOpen: false});
    }
  };

  render() {
    const {isOpen} = this.state;
    return (
      <>
        <section id="cookies" className={`cookies ${(isOpen) ? '' : 'hidden'}`}>
          <div className="container">
            <p><strong>Hello there, it’s Afrivest.</strong> We use cookies to provide you with the best possible user
              experience on our site. If you continue browsing this site, you accept our
              <AniLink
                cover
                direction="right"
                to="/privacy-policy"
                bg="#00EBAA"
                title="Cookies policy">
                cookies policy.
              </AniLink>
            </p>
            <button onClick={this.handleAgreement} type="button" className="g-button g-button--outlined">Accept</button>
          </div>
        </section>
      </>
    )
  }
}

export default Cookies;
